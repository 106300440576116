.has-progress-bar,
.mat-mdc-card.has-progress-bar {
  > .mat-mdc-card-title {
    padding-top: calc(0.5rem - 4px);
  }

  // todo revisit behaviour/implementation
  // it seems like the sole purpose of this override is to hide the progress
  // bar in cards when the loading is completed
  // which can be done in a better way than hiding it with the same color
  // or we can remove this feature at all
  .mat-mdc-progress-bar[mode='determinate'] {
    .mdc-linear-progress__buffer-bar {
      background-color: var(--card-background);
    }
  }
}
