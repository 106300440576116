/**
 * The .button-group below applies to the custom button group that adds a dropdown
 * menu alongside a button, see the app/button-group module.
 */
.button-group {
  button {
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: none;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 0;
    line-height: 36px;
    padding: 0;
    border-radius: 0;
    overflow: visible;
    background-color: transparent;
    color: inherit;
    font: inherit;
  }

  &,
  button {
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
  }

  button {
    border-right: 1px solid #fff;
    padding: 0 16px;

    &:first-child {
      border-left: 0;
      padding-left: 0;
    }

    &:last-child {
      border-right: 0;
      padding-right: 0;
    }
  }

  &.button-group-has-more-menu {
    button:last-child {
      padding-left: 8px;
      margin-right: -8px;

      .mat-icon {
        margin-right: 0 !important;
      }
    }
  }
}

/**
 * Change the height of the default angular button group to match the default
 * height of buttons. For some reason, button groups are larger in Angular which
 * is visually idiotic.
 */
.mat-button-toggle-group {
  height: 32px;
  align-items: center;
}
