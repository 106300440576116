.checkbox-form-field {
  padding: 0.5em;

  &:hover {
    background-color: var(--hover-background);
  }

  .mdc-form-field {
    display: flex;
    .mdc-label {
      flex-grow: 1;
    }
  }

  .mat-mdc-form-field-hint {
    display: block;
    font-size: 12px;
    white-space: normal;
    line-height: 1.5em;
  }

  &.mat-mdc-checkbox-disabled .mat-mdc-form-field-hint {
    color: var(--disabled-text-color);
  }
}

.checkbox-dependent-select {
  --padding: calc(.5rem + 40px);
  padding-left: var(--padding);
  padding-right: var(--padding);
}
