// similar to tailwind style gutter classes
$gutter-sizes: (
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  18,
  20,
  22,
  24,
  26,
  28,
  30,
  32,
  36,
  40,
  44,
  48,
  50,
  52,
  56,
  60,
  64,
  72,
  80,
  90,
  96,
  100,
  120,
  128,
  140,
  160,
  180,
  192,
  200,
  240,
  256,
  280,
  320,
  360,
  400,
  480
);

@each $size in $gutter-sizes {
  .m-#{$size} {
    margin: $size * 0.25rem !important;
  }
  .mt-#{$size} {
    margin-top: $size * 0.25rem !important;
  }
  .mr-#{$size} {
    margin-right: $size * 0.25rem !important;
  }
  .mb-#{$size} {
    margin-bottom: $size * 0.25rem !important;
  }
  .ml-#{$size} {
    margin-left: $size * 0.25rem !important;
  }
  .mx-#{$size} {
    margin-left: $size * 0.25rem !important;
    margin-right: $size * 0.25rem !important;
  }
  .my-#{$size} {
    margin-top: $size * 0.25rem !important;
    margin-bottom: $size * 0.25rem !important;
  }

  .p-#{$size} {
    padding: $size * 0.25rem !important;
  }
  .pt-#{$size} {
    padding-top: $size * 0.25rem !important;
  }
  .pr-#{$size} {
    padding-right: $size * 0.25rem !important;
  }
  .pb-#{$size} {
    padding-bottom: $size * 0.25rem !important;
  }
  .pl-#{$size} {
    padding-left: $size * 0.25rem !important;
  }
  .px-#{$size} {
    padding-left: $size * 0.25rem !important;
    padding-right: $size * 0.25rem !important;
  }
  .py-#{$size} {
    padding-top: $size * 0.25rem !important;
    padding-bottom: $size * 0.25rem !important;
  }
}

.mt-auto {
  margin-top: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}

.p0 {
  padding: 0;
}
