.mat-mdc-button-base {
  &.btn-sm {
    transform: scale(0.75);
  }

  &:not(.mat-mdc-icon-button):not(.mdc-fab):not(.mat-mdc-mini-fab) {
    .mat-icon {
      margin-right: 0.5rem;
    }

    .mdc-button__label {
      display: flex;
      vertical-align: middle;
      justify-content: center;
      align-items: center;
    }
  }
}

.mat-mdc-fab {
  &.fab-right {
    float: right;
    margin-top: -56px !important;
    top: 34px;
    right: 20px;
    z-index: 2;
  }

  @-moz-document url-prefix() {
    .mat-icon {
      // on Firefox mat icons are shifted down for some reason
      // confirmed on official Material v14 page - https://v14.material.angular.io/components/button/overview
      // this line fixes it
      // todo double check after upgrade to v15 - don't see the issue anymore
      margin-top: -.1em;
    }
  }
}

.mat-mdc-icon-button[mat-lg-button] {
  padding: 0 32px !important;
  font-size: 18px;
  line-height: 56px !important;
}

.mat-mdc-icon-button[mat-sm-button] {
  height: 24px;
  width: 24px;
  line-height: 24px;
}

.mat-mdc-icon-button[mat-xs-button] {
  height: 24px;
  width: 24px;
  line-height: 24px;

  .mat-icon {
    font-size: 16px;
    line-height: 20px !important;
    height: 20px;
    width: 20px;
  }
}
