.text-center {
  text-align: center !important;
  justify-content: center;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xxl {
  font-size: 2.25rem;
  line-height: 2.75rem;
}
.uppercase {
  text-transform: uppercase !important;
}
.lowercase {
  text-transform: lowercase !important;
}
.ellipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.w-300 {
  max-width: 300px;
}
.nowrap {
  white-space: nowrap !important;
}
.read-aloud {
  letter-spacing: 10px;
}
.scroll-y {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
}
.hidden {
  display: none !important;
}
