@use '@angular/material' as mat;
@import '../variables';

.mat-mdc-card {
  font-size: 0.875rem;
  margin: var(--card-margin);
  overflow: hidden;

  .mat-mdc-card-title {
    font-size: 1rem !important;
  }

  .mat-divider.mat-divider-horizontal {
    position: relative;
  }

  &.disabled {
    .mat-mdc-table {
      opacity: 0.5;
    }
  }
}

.mat-mdc-card .mat-mdc-card-title .mat-divider,
.mat-divider.full-width {
  margin-left: -24px;
  margin-right: -24px;
}

.mat-mdc-card.default,
.mat-mdc-card.card-table {
  padding: 0;
  margin-bottom: 1rem;
  border-radius: $card-border-radius;

  &:not([class*='mat-elevation-z']) {
    @include mat.elevation($card-box-elevation);
  }

  > .mat-mdc-card-title {
    box-sizing: border-box;
    padding: 0.5rem var(--card-padding);
    line-height: 40px;
    font-weight: 500;
    background: transparent;
    margin: 0;
    border-bottom: $card-border-width solid var(--divider);

    > span {
      letter-spacing: 0.05rem;
      text-transform: uppercase;
      font-size: 0.8125rem;
      font-weight: 700;
      color: var(--primary-text-color);
    }
  }

  &.card-has-subtitle > .mat-mdc-card-title {
    border-bottom: 0;
  }

  &.warn > .mat-mdc-card-title {
    border-color: var(--warn-color);
    > span {
      color: var(--warn-text-color);
    }
  }

  &.expanded {
    width: 100vw;
    height: calc(100vh - #{$popup-header-height});
    margin: 0;
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }

  .mat-mdc-card-subtitle:first-child {
    padding-top: 1rem;
  }
  .mat-mdc-card-subtitle {
    border-bottom: $card-border-width solid var(--divider);
    font-size: 14px;
    line-height: 1;
    margin-bottom: 0;
    padding-bottom: 16px;
    padding-left: var(--card-padding);
    padding-right: var(--card-padding);
  }
  .mat-mdc-card-content {
    padding: var(--card-padding);
    margin-bottom: 0;
    position: relative;

    header.subheading {
      border: $card-border-width solid var(--divider);
      border-left-width: 0;
      border-right-width: 0;
      margin: 1rem -1rem;
      padding: 0.5rem 1rem;

      &.for-table {
        margin-bottom: 0;
      }
    }
  }
  .mat-mdc-card-footer {
    margin: 0;
  }
  .mat-mdc-card-actions {
    background-color: var(--status-bar-background);
    padding: 1.25rem 1rem !important;
  }
  .mat-mdc-card-actions,
  .mat-mdc-card-actions:last-child {
    padding: 0.5rem;
    margin: 0;
  }
}

.mat-mdc-card.card-table {
  > .mat-mdc-card-title {
    background-color: transparent;

    > span {
      letter-spacing: 0.05rem;
      text-transform: uppercase;
      font-size: 0.8125rem;
      font-weight: 700;
      color: var(--primary-text-color);
    }

    .mat-mdc-slide-toggle {
      font-size: 0.8125rem;
    }
  }

  .mat-mdc-table {
    --table-padding: var(--card-padding);
    width: 100%;

    th.mat-mdc-header-cell {
      text-wrap: nowrap;
    }
  }

  .mat-mdc-card-content {
    padding: 0;
    overflow-x: auto;
  }

  .mmx-card-padding {
    padding: var(--card-padding);
  }
}

.mat-mdc-card.p-0 {
  .mat-mdc-card-title .card-title-text {
    padding: 1rem 1.5rem;
  }
  .mat-mdc-card-title .card-title-text .card-control {
    height: 24px;
    width: 24px;
    line-height: 24px;
  }
  .mat-mdc-card-title .card-title-text .mat-card-subtitle {
    margin: 0;
  }
  .mat-mdc-card-title .mat-divider {
    margin-left: 0;
    margin-right: 0;
    border-top-color: rgba(0, 0, 0, 0.04);
  }
  .mat-mdc-card-image {
    margin: 0 0 1rem !important;
  }
  .mat-mdc-card-content {
    padding: 0 1.5rem 1.5rem;
  }
}

.mat-mdc-card .mat-mdc-card-content.p-0 > .mat-mdc-nav-list {
  padding-top: 0;
}

/*------- quill rich text editor ----------*/

.p-0.mat-mdc-card-content {
  .ql-container {
    border: 0 !important;
  }

  .ql-toolbar.ql-snow {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}
/*-----------------------------------------*/

@media (max-width: 767px) {
  .mat-mdc-card-title .mat-divider {
    margin-left: 0;
    margin-right: 0;
  }
}
