.scrollable {
  position: relative;
  width: 100%;
  height: 100vh;
}

.product-category-dot {
  width: 14px;
  height: 14px;
  margin-right: 4px;
  border-radius: 50%;
}

.product-category-dot-large {
  width: 25px;
  height: 25px;
  margin-left: 20px;
  border-radius: 50%;
}

.codename {
  font-size: 10px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  max-width: 36px;
}
