.mat-mdc-table {
  --table-padding: 0.25rem;

  // todo rename custom classes to avoid confusion
  // using mat- prefix for our custom classes is misleading
  &.mat-table--wide {
    width: 100%;

    .mat-mdc-header-cell:nth-child(1) {
      flex: 0 0 55px;
      justify-content: center;
    }
    .mat-mdc-cell:nth-child(1) {
      flex: 0 0 30px;
      justify-content: center;
    }
    .mat-mdc-footer-cell {
      padding: var(--table-padding);
      font-weight: 500;
    }
  }

  &.mat-compact-table {
    .mat-mdc-header-row,
    tr.mat-mdc-row,
    tr.mat-mdc-footer-row {
      height: 42px;
    }
  }

  &.mat-bordered-table {
    th.mat-mdc-header-cell {
      text-align: center;
    }
    th.mat-mdc-header-cell,
    td.mat-mdc-cell {
      border-right: 1px solid var(--divider);
    }
  }

  &.mat-table--striped {
    .mat-mdc-row:nth-of-type(odd) {
      &,
      .mat-mdc-cell {
        background-color: var(--status-bar-background);
      }
    }
  }

  &.mat-table--hover {
    .mat-mdc-row:hover {
      &,
      .mat-mdc-cell {
        background-color: var(--hover-background);
      }
    }
  }

  &.mat-table--actionable {
    .mat-mdc-row,
    .mat-mdc-cell {
      cursor: pointer;
    }
  }

  .mat-mdc-header-cell {
    padding: var(--table-padding);
    box-sizing: border-box;
  }
  .mat-mdc-row {
    &.hasFooter .mat-mdc-cell {
      border-bottom: 0;
    }
    &.selected {
      background-color: var(--selected-background);
    }
    &.row-muted {
      font-weight: 300;
    }
  }
  .mat-mdc-cell,
  .mat-mdc-header-cell {
    &[width] {
      overflow: hidden;
    }
  }
  .mat-mdc-cell {
    box-sizing: border-box;
    padding: var(--table-padding);
    vertical-align: middle;
    .label {
      font-weight: 500;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
}
