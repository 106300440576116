.preview-text-message {
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
  background-color: var(--background);
  position: relative;

  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: -7px;
    height: 20px;
    width: 20px;
    background: var(--background);
    border-bottom-right-radius: 15px;
  }
  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -10px;
    width: 10px;
    height: 20px;
    background: var(--card-background);
    border-bottom-right-radius: 10px;
  }
}

.preview-email-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;

  a.link-box,
  a.link-button {
    color: #06ba98 !important;
    text-decoration: none !important;
    display: block;
    padding: 16px;
    border-radius: 5px;
    background-color: #f7f7f7;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 15px;
    text-align: center;
  }

  div.link-button-parent {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a.link-button {
    display: inline-block;
    color: #fff !important;
    background-color: #06ba98 !important;
    width: auto;
    font-size: 16px;
    margin: 15px auto;
  }

  /*
    @tab Page
    @section Heading 1
    @style heading 1
    */
  h1 {
    color: #222222;
    font-family: Helvetica sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: bold;
    line-height: 150%;
    letter-spacing: normal;
    text-align: center;
  }

  /*
    @tab Page
    @section Heading 2
    @style heading 2
    */
  h2 {
    color: #222222;
    font-family: Helvetica sans-serif;
    font-size: 34px;
    font-style: normal;
    font-weight: bold;
    line-height: 150%;
    letter-spacing: normal;
    text-align: left;
  }

  /*
    @tab Page
    @section Heading 3
    @style heading 3
    */
  h3 {
    color: #444444;
    font-family: Helvetica sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: bold;
    line-height: 150%;
    letter-spacing: normal;
    text-align: left;
  }

  /*
    @tab Page
    @section Heading 4
    @style heading 4
    */
  h4 {
    color: #999999;
    font-family: Georgia sans-serif;
    font-size: 20px;
    font-style: italic;
    font-weight: normal;
    line-height: 125%;
    letter-spacing: normal;
    text-align: left;
  }

  .templateHeader {
    background-color: #40c9ae;
    border-top: 5px solid #7edbc8;
    border-bottom: 0;
    height: 126px;
    margin-bottom: -90px;
    width: 100%;
  }

  .preview-email-message--content {
    background-color: #ffffff;
    border-top: 0;
    border-bottom: 0;
    padding-top: 0;
    padding-bottom: 45px;
    border-radius: 20px;
    width: 600px;
    min-height: 200px;
    color: #222222;
    margin-bottom: 40px;
  }

  &,
  p {
    color: #808080;
    font-family: Helvetica sans-serif;
    font-size: 16px;
    line-height: 150%;
    text-align: left;
  }

  a,
  p a {
    color: #40c9ae;
    font-weight: normal;
    text-decoration: underline;
  }
}
