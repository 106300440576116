@import 'ppal-variables';

// Main Variables File

$popup-header-height: 36px;

// Cards
$card-border-radius: 4px;
$card-border-width: 1px;
$card-box-elevation: 2;
