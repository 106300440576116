@use '@angular/material' as mat;

@import 'mixins/ppal-theming';

@include apply-console-themes(false) {
  $color: map-get($theme, color);
  $is-dark-theme: map-get($color, is-dark);
  $primary: map-get($color, primary);
  $accent: map-get($color, accent);
  $warn: map-get($color, warn);
  $foreground: map-get($color, foreground);

  $underline-color-hover: mat.get-color-from-palette(
    $foreground,
    divider,
    if($is-dark-theme, 1, 0.87)
  );
  $underline-color-warn: mat.get-color-from-palette($warn, text);

  $fill-background: mat.get-color-from-palette($foreground, base, if($is-dark-theme, 0.1, 0.04));
  $underline-color: mat.get-color-from-palette(
    $foreground,
    divider,
    if($is-dark-theme, 0.5, 0.42)
  );
  $underline-focused-color: mat.get-color-from-palette($primary, text);
  $label-color: mat.get-color-from-palette(
    $foreground,
    secondary-text,
    if($is-dark-theme, 0.7, 0.6)
  );

  .StripeElement {
    background-color: $fill-background;
    border-color: $underline-color;
    color: $label-color;
  }

  .StripeElement:hover {
    border-color: $underline-color-hover;
  }

  .StripeElement--focus {
    border-color: $underline-focused-color !important;
  }

  .StripeElement--invalid {
    color: $underline-color-warn;
    border-color: $underline-color-warn !important;
  }
}

.StripeElement {
  margin: 0 0 1.34375em;
  border-width: 0;
  border-bottom-width: 1px;
  border-style: solid;
  border-radius: 4px 4px 0 0;
  padding: 1em 0.75em 1em 0.75em;
  transition: padding-bottom 150ms ease, border-width 150ms ease, border-color 150ms ease;
}

.StripeElement:hover,
.StripeElement--focus,
.StripeElement--invalid {
  padding-bottom: calc(1em - 1px);
  border-bottom-width: 2px;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
