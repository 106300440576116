.mat-expansion-panel {
  .mat-expansion-panel-header {
    font-size: 13px !important;
    font-weight: 400;

    .mat-expansion-panel-header-title {
      font-size: 14px;
      color: var(--primary-text-color) !important;
      transition: font-size 225ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .mat-expansion-panel-header-description {
      display: flex;
      flex-basis: content !important;
      flex-grow: 0 !important;
      color: rgba(65, 64, 66, 0.5) !important;
    }

    &.mat-expanded {
      .mat-expansion-panel-header-title {
        font-size: 1.3rem;
      }
    }
  }

  .mat-expansion-panel-content {
    font-weight: normal !important;
  }
}
