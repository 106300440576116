.timeline {
  position: relative;
  overflow: hidden;
}
.timeline::before {
  content: '';
  position: absolute;
  top: 20px;
  bottom: 0;
  left: 24px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.12);
}
.timeline .timeline-item {
  width: 100%;
  overflow: hidden;
  margin-bottom: 24px;
}
.timeline .timeline-item:last-child {
  margin-bottom: 0px;
}
.timeline .timeline-badge {
  float: left;
  position: relative;
  margin-right: 30px;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  overflow: hidden;
}
.timeline .timeline-badge .icon-badge {
  text-align: center;
  width: 48px;
  height: 24px;
  line-height: 24px;
}
.timeline .timeline-body-top {
  margin-bottom: 8px;
}
.timeline .timeline-body {
  float: right;
  width: calc(100% - 78px);
}

.timeline .timeline-body-content > :last-child {
  margin-bottom: 8px;
}
